@import "scss/themes.scss";

[data-namespace="admin-001"] {
  .main-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    span {
      text-transform: capitalize;
      // font-style: italic;
      font-size: 26px;
      font-weight: 300;
      color: rgba(255, 255, 255, .5);
      transition: all .25s ease;
      position: relative;
      top: 1px;
      &.divider {
        background: rgba(255, 255, 255, .25);
        height: 30px;
        width: 2px;
        margin: 0 5px;
        top: 0;
      }
    }
    &:hover, &:focus, &:active {
      span {
        color: #fff;
      }
    }
  }
}
