// Theme variables.
$--accent-background: --accent-background;
$--anchor: --anchor;
$--anchor-hover: --anchor-hover;
$--cta: --cta;
$--cta-hover: --cta-hover;
$--danger: --danger;
$--elocal-black: --elocal-black;
$--elocal-blue: --elocal-blue;
$--elocal-green: --elocal-green;
$--elocal-light-blue: --elocal-light-blue;
$--elocal-light-green: --elocal-light-green;
$--elocal-light-red: --elocal-light-red;
$--elocal-red: --elocal-red;
$--error: --error;
$--font-color: --font-color;
$--warning: --warning;

// Default theme.
$theme-map-default: (
  $--accent-background: #e9e9e9,
  $--anchor: #016ea2,
  $--anchor-hover: #e53c07,
  $--cta: #016ea2,
  $--cta-hover: #e53c07,
  $--danger: #d70500,
  $--elocal-black: #1c2f39,
  $--elocal-blue: #02405F,
  $--elocal-green: #277239,
  $--elocal-light-blue: #016ea2,
  $--elocal-light-green: #02903b,
  $--elocal-light-red: #e53c07,
  $--elocal-red: #B73E18,
  $--error: #ff0c00,
  $--font-color: #1c1c1c,
  $--warning: #f98202,
);
