@import "scss/themes.scss";

[data-namespace="admin-001"] {
  footer {
    background: rgba(0, 0, 0, .8);
    padding: 10px 20px;
    .copyright {
      display: block;
      font-size: 12px;
      color: rgba(255, 255, 255, .75);
      font-weight: 300;
      text-align: center;
    }
  }
}
