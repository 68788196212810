@import 'scss/themes.scss';

[data-namespace='admin-001'] {
  ::placeholder {
    opacity: 0.6;
  }
  p {
    margin: 10px 0;
  }
  h1 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2em;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  h2 {
    font-size: 36px;
    font-weight: 500;
    line-height: 1.2em;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  h3 {
    font-size: 15px;
    font-weight: 300;
    line-height: 1.2em;
  }
  h4 {
    font-size: 28px;
    font-weight: 500;
    line-height: 1.2em;
    @media (max-width: 480px) {
      font-size: 20px;
    }
  }
  h5 {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2em;
    @media (max-width: 480px) {
      font-size: 16px;
    }
  }
  .isLoadingSiteData {
    opacity: 0;
  }
  .desktop {
    @media (max-width: 480px) {
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 480px) {
      display: block;
    }
  }
  a,
  button {
    transition: all 0.25s ease;
    color: var($--anchor);
    text-decoration: none;
    font-weight: 300;
  }
  a:hover,
  a:focus,
  a:active {
    color: var($--anchor-hover);
  }
  form {
    // width: 350px;
    margin: 20px auto auto auto;
    //border: 1px solid var($--accent-background);
    .form-header {
      padding: 10px 15px;
      background: var($--accent-background);
    }
    .form-main {
      padding: 30px 15px;
    }
    .form-footer {
      padding: 10px 15px;
      border-top: 1px solid var($--accent-background);
    }
    .actions-container {
      display: flex;
      flex-direction: column;
      > * {
        margin: 5px;
      }
    }
  }
  .input-container {
    position: relative;
    margin-bottom: 8px;
  }
  input,
  textarea,
  select {
    background: none;
    padding: 0 8px;
    font-size: 18px;
    box-shadow: none;
    border: 1px solid var($--accent-background);
    border-radius: 5px;
    height: 40px;
    width: 100%;
    display: block;
    &:active,
    &:focus {
      border-color: #17a3dd;
    }
    @media (max-width: 480px) {
      display: block;
      margin: 5px 0 0;
      font-size: 16px;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  input[role='combobox'] {
    height: 100%;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='checkbox'] {
    width: unset;
    height: unset;
    display: inline;
    margin-right: 5px;
  }
  .inline-select {
    display: inline;
    width: unset;
  }
  .submit {
    height: 40px;
    border: 1px solid rgb(241, 232, 232);
    border-radius: 5px;
    padding: 0 12px;
    background: var($--cta);
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    cursor: pointer;
    &:hover,
    &:focus,
    &:active {
      background: var($--cta-hover);
    }
  }
  .icon-button {
    height: auto;
    width: auto;
    border: none;
    background: none;
    display: flex;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .action-button {
    background: transparent;
    padding: 5px 10px;
    border: solid 1px #eee;
    cursor: pointer;
  }
  .disabled {
    pointer-events: none;
    background: var($--accent-background);
    &:hover,
    &:focus,
    &:active {
      background: var($--accent-background);
    }
  }
  .container {
    width: 100%;
    padding: 20px;
  }
  .rail-container {
    display: flex;
    justify-content: space-between;
    &.inverse {
      .main-content {
        padding-right: 30px;
        padding-left: 0;
      }
    }
    .main-content {
      flex: 5;
      padding-right: 30px;
      @media (max-width: 768px) {
        padding-right: 0;
      }
      h2 {
        text-align: left;
      }
      img {
        max-width: 100%;
        border-radius: 5px;
      }
    }
    .rail {
      flex: 2;
      &.large {
        flex: 3;
      }
    }
  }
  .error {
    color: var($--error);
    font-size: 15px;
    font-weight: 300;
  }
  .panel {
    background: #fff;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 40px;
    .panel-header,
    .panel-footer {
      background: #fff;
      padding: 10px 0;
    }
    .panel-header {
      font-size: 1.2em;
      border-bottom: 1px solid var($--accent-background);
      font-size: 14px;
      font-weight: 300;
      // opacity: .65;
    }
    .panel-footer {
      font-size: 0.9em;
    }
    .panel-body {
      padding: 10px 0;
    }
  }
  .text-center {
    text-align: center;
  }
}
