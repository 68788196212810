@import 'scss/themes.scss';
@import 'scss/template.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none !important;
}

html {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  height: 100%;
  width: 100%;
}

body {
  height: 100%;
  width: 100%;
  font-weight: 300;
  line-height: 1.5;
  color: var($--font-color);
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  // font-family: "Open Sans", sans-serif;
  font-family: 'Poppins', sans-serif;
  // background: var($--accent-background);
  &.fixed {
    position: fixed;
    @media (max-width: 480px) {
      position: static;
    }
  }
}

.page-loading-wrap {
  width: 100%;
  height: 100%;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wrap {
  min-height: calc(100vh - 107px);
}

.main-container {
  min-height: calc(100vh - 107px);
  padding: 20px;
  max-width: 1500px;
  margin: auto;
}

@media (max-width: 675px) {
  .main-container {
    padding: 20px 10px;
  }
}
