@import 'scss/themes.scss';

[data-namespace='admin-001'] {
  .error-fallback {
    max-width: 800px;
    margin: auto;
    text-align: center;
    margin-top: 300px;
    > * {
      margin-bottom: 20px;
    }
  }
}
