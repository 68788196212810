@import 'scss/themes.scss';

[data-namespace='admin-001'] {
  header {
    z-index: 3;
    background: #02405e;
    z-index: 3;
    .main {
      display: flex;
      padding: 15px 20px;
      position: relative;
      align-items: center;
      &.isOpen {
        .actions-container {
          inset: 0;
          opacity: 1;
          @media (max-width: 768px),
            (max-width: 480) and (-webkit-min-device-pixel-ratio: 2) {
            z-index: 1;
          }
        }
      }
      .main-logo-container {
        img,
        svg {
          width: 96px;
        }
      }
      .company-name {
        font-size: 16px;
        margin-left: 20px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.6);
        margin-right: 15px;
      }
      .account-change-container {
        margin-left: 15px;
        width: 20px;
        height: 20px;
        color: red;
        cursor: pointer;
      }
      .actions-container {
        // flex: 4;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        transition: all 0.25s ease;
        @media (max-width: 768px),
          (max-width: 834px) and (-webkit-min-device-pixel-ratio: 2) {
          position: fixed;
          flex-direction: column;
          justify-content: flex-start;
          top: 0;
          padding-top: 85px;
          right: -100vw;
          height: 100vh;
          opacity: 0;
          align-items: flex-end;
          width: 100vw;
          display: flex;
          background: #fff;
        }
        a,
        > div {
          font-size: 14px;
          margin-left: 20px;
          font-weight: 600;
          color: rgba(255, 255, 255, 1);
          @media (max-width: 768px),
            (max-width: 834px) and (-webkit-min-device-pixel-ratio: 2) {
            font-size: 1.5rem;
            margin: 5px 10px;
            color: rgba(0, 0, 0, 1);
          }
          &:hover {
            color: rgba(255, 255, 255, 0.6);
          }
        }
        .drop-trigger {
          position: relative;
          cursor: pointer;
          color: rgba(255, 255, 255, 1);
          transition: all 0.25s ease;
          &:hover,
          &:active,
          &:focus {
            color: rgba(255, 255, 255, 0.6);
            .drop-container {
              display: flex;
            }
          }
          @media (max-width: 768px) {
            margin: 0;
            label {
              display: none;
            }
          }
          .drop-container {
            display: none;
            position: absolute;
            top: 22px;
            right: -10px;
            background: #02405e;
            flex-direction: column;
            width: 100px;
            z-index: 3;
            @media (max-width: 768px) {
              display: flex;
              position: static;
              border: none;
              width: auto;
              background: #fff;
            }
            > * {
              padding: 10px;
              margin: 0;
              cursor: pointer;
              color: rgba(255, 255, 255, 0.6);
              transition: all 0.25s ease;
              text-align: right;
              &:hover,
              &:active,
              &:focus {
                color: #fff;
              }
              @media (max-width: 768px) {
                padding: 0;
                margin: 5px 10px;
                color: rgba(0, 0, 0, 1);
              }
            }
          }
        }
      }
    }
    .flyout-trigger {
      display: none;
      // position: fixed;
      // top: 12px;
      // right: 12px;
      cursor: pointer;
      margin-left: 20px;
      @media (max-width: 768px),
        (max-width: 834px) and (-webkit-min-device-pixel-ratio: 2) {
        display: block;
        z-index: 1;
      }
      span {
        height: 3px;
        width: 31px;
        background: #fff;
        display: block;
        margin: 0 auto 5px;
        transform-origin: 13% 50%;
        transition: transform 0.25s ease;
      }
      &.isOpen {
        span {
          background: var($--font-color);
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            opacity: 0;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      }
    }
  }
}
